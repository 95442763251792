@import "bootstrap/scss/bootstrap";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
    font-family: "Lato";
    src: url('assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url('assets/fonts/Lato/Lato-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url('assets/fonts/Lato/Lato-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url('assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('assets/fonts/Open-Sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('assets/fonts/Open-Sans/OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('assets/fonts/Open-Sans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('assets/fonts/Open-Sans/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('assets/fonts/Open-Sans/OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

.ql-editor {
    min-height: 100px;
}

.ql-mention-list {
    background-color: white;
    list-style-type: none;
    transform: translateY(-2px);
    margin-top: 0;
    padding: 0px 0px 0px 0px;
    z-index: 100;
    max-height: 300px;
    overflow: scroll;
    border-radius: 10px;
}

.ql-mention-list .ql-mention-list-item {
    cursor: pointer;
    padding: 0px 15px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Lato", sans-serif;
}

.ql-mention-list .ql-mention-list-item.selected {
    background-color: #EBEFF8;
}

a.mention {
    // quill mention styling
    background-color: #f0f2fc;
    border-radius: 6px;
    height: 24px;
    margin-right: 2px;
    padding: 3px 3px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Lato", sans-serif;
    text-decoration: none !important;
    color: #2059BF;
}


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Lato", sans-serif;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 10000;
    visibility: visible;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.565);
    transition: all 0.3s ease-in-out;

    &.fadeOut {
        opacity: 0;
        visibility: hidden;
    }

    .spinner {
        background-color: #fff;
    }
}

.spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}

.error-page-container {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Lato", sans-serif;

    .logo-container {
        font-size: 36px;
        display: flex;
        align-items: center;
    }

    .error-page-title {
        font-size: 30px;
		text-align: center;
    }

    .error-page-message {
        font-size: 18px;
		text-align: center;
    }
}

.vsight-button {
    padding-inline: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    &.left {
        margin-right: 20px;
    }

    &:hover {
        background-color: #2059BF !important;
        border: solid 1px !important;
        border-color: #2059BF !important;
    }
}

.vsight-button-outline-secondary {
    background-color: #ffffff !important;
    border: solid 1px !important;
    border-color: #707070 !important;
    color: #707070 !important;
    border-radius: 10px !important;

    &:hover {
        background-color: #E1E1E1 !important;
        border: solid 1px !important;
        border-color: #707070 !important;
    }
}

.vsight-button-outline-primary {
    background-color: #ffffff !important;
    border: solid 1px !important;
    border-color: #003DA6 !important;
    color: #003DA6 !important;
    border-radius: 10px !important;

    &:hover {
        background-color: #EBEFF8 !important;
        border: solid 1px !important;
        border-color: #003DA6 !important;
    }
}

.vsight-danger {

    &:hover {
        background-color: #bb2d3b !important;
        border-color: #bb2d3b !important;
    }
}

.ng-select {
    &.ng-select-disabled>.ng-select-container {
        background-color: #e9ecef;
    }

    .ng-select-container {
        border: 1px solid #ced4da;
        min-height: 48px;

        .ng-value-container {
            .ng-placeholder {
                font-size: 16px;
            }
        }

        border-radius: 10px !important;
    }

    .ng-dropdown-panel {
        .ng-option {
            font-size: 14px !important;
            font-weight: 500;
        }

        border-radius: 10px !important;
        margin-top: 2px;

        .ng-dropdown-panel-items {
            border-radius: 10px;
        }
    }

    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                .ng-placeholder {
                    font-size: 14px;
                    top: 50% !important;
                    transform: translate(0, -50%) !important;
                    padding: 0 0 0 3px !important;
                }

                .ng-value {
                    .ng-value-icon {
                        padding: 4px 8px !important;
                    }
                }
            }
        }
    }
}

.v-btn {

    /*
    &.btn-primary, &.btn-primary:active, &.btn-primary:visited {
        background-color: #2145bf;
        border-color: #2145bf;
    }

    &.btn-primary:hover {
        background-color: #2a63f6;
        border-color: #2a63f6;
    }
*/
    &.btn-primary:disabled,
    &.btn-outline-primary:disabled,
    &.btn-secondary:disabled,
    &.btn-outline-secondary:disabled,
    &.btn-success:disabled,
    &.btn-outline-success:disabled,
    &.btn-danger:disabled,
    &.btn-outline-danger:disabled,
    &.btn-warning:disabled,
    &.btn-outline-warning:disabled,
    &.btn-info:disabled,
    &.btn-outline-info:disabled,
    &.btn-light:disabled,
    &.btn-outline-light:disabled,
    &.btn-dark:disabled,
    &.btn-outline-dark:disabled {
        background-color: #ffffff;
        border-color: #e0e0e0;
        color: #acacac;
    }

    &.btn-primary,
    &.btn-primary:active,
    &.btn-primary:visited {
        background-color: #002E7D !important;
        border-color: #002E7D !important;
    }

    &.btn-primary:disabled {
        background-color: #003DA6;
        border-color: none;
        opacity: 70%;
        color: white;
    }

    &.btn-primary:hover {
        background-color: #2059BF !important;
        border-color: #2059BF !important;
    }

    &.btn-danger,
    &.btn-danger:active,
    &.btn-danger:visited {
        background-color: #A72124;
        border-color: #A72124;
    }

    &.btn-outline-primary,
    &.btn-outline-primary:active,
    &.btn-outline-primary:visited {
        border: none;
        border-color: rgba(222, 235, 255, 0.9);
    }

    &.btn-outline-primary:hover {
        color: #0d6efd;
        background-color: rgba(222, 235, 255, 0.9);
    }

    &.btn-outline-secondary,
    &.btn-outline-secondary:active,
    &.btn-outline-secondary:visited {
        background-color: #ffffff;
        border: none;
        border-color: #e0e0e0;
        color: #303030;
    }

    &.btn-outline-secondary:hover {
        background-color: #ececec;
    }

    &.btn-outline-success,
    &.btn-outline-success:active,
    &.btn-outline-success:visited {
        border: none;
        border-color: rgba(187, 244, 205, 0.9);
    }

    &.btn-outline-success:hover {
        color: #198754;
        background-color: rgba(187, 244, 205, 0.9);
    }

    &.btn-outline-danger,
    &.btn-outline-danger:active,
    &.btn-outline-danger:visited {
        border: none;
        border-color: rgba(244, 189, 194, 0.9);
    }

    &.btn-outline-danger:hover {
        color: #dc3545;
        background-color: rgba(244, 189, 194, 0.9);
    }

    &.btn-outline-warning,
    &.btn-outline-warning:active,
    &.btn-outline-warning:visited {
        border: none;
        border-color: rgba(249, 226, 156, 0.9);
    }

    &.btn-outline-warning:hover {
        background-color: rgba(249, 226, 156, 0.9);
    }

    &.btn-outline-info,
    &.btn-outline-info:active,
    &.btn-outline-info:visited {
        border: none;
        border-color: rgba(169, 240, 254, 0.9);
    }

    &.btn-outline-info:hover {
        background-color: rgba(169, 240, 254, 0.9);
    }

    &.btn-outline-light,
    &.btn-outline-light:active,
    &.btn-outline-light:visited {
        border: none;
        color: #555555;
    }

    &.btn-outline-light:hover {
        background-color: #ececec;
    }

    &.btn-outline-dark,
    &.btn-outline-dark:active,
    &.btn-outline-dark:visited {
        border: none;
    }

    &.btn-outline-dark:hover {
        color: #000000;
        background-color: #b4b4b4;
    }
}

.modal {

    // overflow-x: scroll;
    .modal-dialog.modal-always-lg {
        width: 768px;
        max-width: 768px;
    }
}

.modal-body {
    padding: 40px;
    padding-inline: 55px;
}

.modal-header {
    background-color: #000F29;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 55px;

    .modal-title {
        font-size: 16px;
        color: white;
        font-family: "Lato", sans-serif;
        -webkit-font-smoothing: antialiased;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 750px;
    }

    .image-modal-lg {
        max-width: 650px;
    }
}

.modal-template {
    width: 600px;
}

@media only screen and (min-width: 768px) {
    .modal {
        .modal-dialog.modal-always-lg {
            width: 96%;
            max-width: 96%;
        }
    }
}

@media only screen and (min-width: 992px) {
    .modal {
        .modal-dialog.modal-always-lg {
            // width: 952px;
            // max-width: 952px;
            width: 1240px;
            max-width: 1240px;
        }
    }
}

.v-card {
    &.card {
        .card-header {
            padding-top: 0;
            padding-bottom: 0;
            height: 60px;
            font-size: 13px;
            display: flex;
            align-items: center;
            background-color: #ffffff;
            border-bottom: 1px solid #F1F0F0;
        }

        .card-footer {
            padding-top: 0;
            padding-bottom: 0;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: #ffffff;
            border-top: 1px solid #F1F0F0;

            button {
                padding: 8px 12px;
            }

            button:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.lato-thin {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.lato-thin-italic {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.lato-light {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.lato-light-italic {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.lato-regular-italic {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.lato-bold-italic {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.lato-black-italic {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: italic;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.fs-32 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    -webkit-font-smoothing: antialiased;
}

.fs-24 {
    font-style: normal;
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.fs-20 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: #000;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.fs-18 {
    font-style: normal;
    font-size: 18px !important;
    color: #000;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.fs-16 {
    font-style: normal;
    font-size: 16px !important;
    color: #000;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.fs-15 {
    font-style: normal;
    font-size: 15px !important;
    color: #000;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.fs-14 {
    font-style: normal;
    font-size: 14px !important;
    color: #000;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.fs-12 {
    font-style: normal;
    font-size: 12px !important;
    color: #000;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.fs-11 {
    font-style: normal;
    font-size: 11px !important;
    color: #000;
    -webkit-font-smoothing: antialiased;
}

.fs-13 {
    font-style: normal;
    font-size: 13px !important;
    color: #000;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.text-light-black {
    color: #000F29 !important;
}

.text-grey {
    color: #A1A1A1 !important;
}

.text-dark-grey {
    color: #707070 !important;
}

.text-red {
    color: #B94E4F !important;
}

.text-white {
    color: white;
}

.text-black {
    color: #000000;
}

.text-blue {
    color: #003DA6;
}

.text-light-grey {
    color: #707070;
}

formly-field {
    label {
        font-size: 14px;
        color: #393939;
        font-style: normal;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1170px) {
    formly-field {
        label {
            font-size: 1vw;
            color: #393939;
            font-style: normal;
            font-weight: 400;
        }
    }

    .label-preview-title {
        font-size: 1vw;
        color: #393939;
        font-style: normal;
        font-weight: 400;
    }
}

.custom-checkbox {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
}

.custom-radio {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
}

.custom-control-label {
    padding-left: 11px;
}

.validation-text {
    color: #EC0000;
    font-size: 12px;
    margin-top: 8px;
}

.core-blue {
    color: #003DA6 !important;
}

.color-blue-dark {
    color: #002668;
}

.form-control {
    border-radius: 10px;
    min-width: 125px;
    height: 40px;
    min-height: 36px;
    border-color: #E1E1E1;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
}

.table-form-control {
    border-left: none;
    border-top: none;
    border-right: none;
    min-width: 125px;
    border-color: #E1E1E1;
}

.h-30 {
    height: 30px;
}

.ng-select.ng-select-opened>.ng-select-container {
    background: #fff;
    border-color: #E1E1E1 #E1E1E1 #E1E1E1;
}

.ng-select .ng-select-container {
    border: 1px solid #E1E1E1;
    min-height: 40px;
}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #E1E1E1;
    left: 0;
}

.form-check-input:checked[type=checkbox] {
    background-color: #003DA6 !important;
    border-color: #003DA6 !important;
}

.form-check-input {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
}

.form-check-input:checked[type=radio] {
    background-color: #003DA6;
    border-color: #003DA6;
}

.top-gap {
    margin-top: 24px;
}

.top-gap-28 {
    margin-top: 28px;
}

.top-gap-30 {
    margin-top: 30px;
}

.top-gap-8 {
    margin-top: 8px;
}

.top-gap-12 {
    margin-top: 12px;
}

.top-gap-16 {
    margin-top: 16px;
}

.label-preview {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    -webkit-font-smoothing: antialiased;
}

.modal-content {
    border: none !important;
    border-radius: 10px;
}

// .modal {
//     &:last-child {
//         background: rgba(0,0,0,0.5);
//     }
// }
.mat-header-row {
    display: flex;
    min-width: 800px;
    padding-top: 16px;
    height: 50px !important;
}

.mat-sort-header.centered {
    .mat-sort-header-container {
        justify-content: center;
    }
}

.mat-table {
    width: -webkit-fill-available;
    width: -moz-available;
}

.mat-row {
    min-height: 60px;
}

tr.mat-row,
tr.mat-footer-row {
    height: auto !important;
    min-height: 90px !important;
}

.table-fs-14 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    -webkit-font-smoothing: antialiased;
}

.label-info {
    font-size: 12px;
    font-weight: 400;
    color: lightgray;
}

.form-group {
    &label {
        display: none;
    }
}

.number {
    width: 50px;
    min-height: 36px;
}

.qr-button {
    background-color: #AAAAAA;
    border-color: #AAAAAA;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
}

.qr-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 140%;
}

.label-preview-title {
    display: flex !important;
    font-size: 1vw;
    color: #393939;
    font-style: normal;
    font-weight: 400;

}

.form-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.form-video {
    width: 100%;
    height: 100%;
}

.image-with-label {
    margin: 34px 9px 9px 9px !important;
}

.image-with-label-gallery {
    margin: 34px 40px 9px 35px !important;
}

.image-without-label-gallery {
    margin: 0px 33px 5px 32px;
}

textarea {
    height: auto !important;
}

.custom-style {
    color: white !important;
    min-height: 60px !important;
    opacity: 0.9 !important;
    display: flex !important;
    align-items: center;
    padding-left: 22px;
    min-width: 320px !important;

    &.success {
        background: #334C9C !important;
    }

    &.error {
        background: #c90808 !important;
    }
}

.mat-simple-snackbar {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    line-height: 140%;
}

td {
    border-bottom-color: #f6f6f6 !important;
    font-size: 14px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

.mat-header-cell {
    color: #000F29 !important;
    border-bottom-color: #ececec !important
}

@mixin btn-primary-active-style {
    color: #314B76;
    background-color: #EFF1F5 !important;
    border-color: #EFF1F5 !important;
    box-shadow: none !important;
}

.dropdown-menu::-webkit-scrollbar {
    display: none;
}

.action-dropdown {
    height: 35px;
    background-color: #EBEFF8 !important;
    color: #314B76 !important;
    font-size: 14px !important;
    border-color: #EBEFF8 !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 400;

    &:hover {
        background-color: #EBEFF8;
        color: #314B76;
        font-size: 14px !important;
        border-color: #EBEFF8;
        z-index: 0 !important;
    }

    &.btn-primary {
        &:focus {
            @include btn-primary-active-style;
        }

        &:active {
            @include btn-primary-active-style;
        }
    }
}



@mixin btn-danger-active-style {
    color: #fff;
    background-color: #CCD8ED !important;
    border-color: #CCD8ED !important;
    box-shadow: none !important;
}

.dropdown-toggle-split {
    background-color: #CCD8ED;
    border-color: #CCD8ED;
    height: 35px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 20px;

    &:hover {
        background-color: #CCD8ED;
        border-color: #CCD8ED;
    }

    &:focus {
        box-shadow: none !important;
    }

    &.btn-danger {
        &:focus {
            @include btn-danger-active-style;
        }

        &:active {
            @include btn-danger-active-style;
        }

        .show>&.dropdown-toggle {
            @include btn-danger-active-style;
        }
    }
}

.dropdown-wrapper-list {
    width: 120px;

    .dropdown-menu {
        margin-top: 3px;
        padding: 0;
        right: 0 !important;
        border-radius: 10px;
        min-width: 145px;
        border-color: #EBEFF8;

        .dropdown-item {
            cursor: pointer;
            padding: 10px 0 10px 16px;
            user-select: none;
            font-size: 14px;
            font-weight: 400;
            color: #314B76;
            -webkit-font-smoothing: antialiased;
            white-space: break-spaces;

            &:hover,
            &:active {
                background-color: rgba(0, 61, 166, 0.08);
                border-radius: 10px;
            }
        }
    }
}

.filter-input {
    margin-left: 20px;
    border: 1px solid #E1E1E1;
    min-height: 38px;
    min-width: 280px;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 6px;

    ::placeholder {
        color: #707070;
    }
}

::placeholder {
    color: #707070;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato", sans-serif;
}

.search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);

    img {
        width: 16px;
        height: 16px;
    }
}

.create-button {
    background-color: #002E7D;
    border-color: #002E7D;
    padding-inline: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 10px;
    color: white;

    &:hover {
        background-color: #2059BF !important;
        border-color: #2059BF !important;
        color: white !important;
    }
}

.list-header {
    border-bottom-style: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    min-height: 75px;
    border: none;
    border-bottom: solid 1px #ececec;
    padding-right: 70px !important;
    padding-left: 45px !important;
    height: 95px;
}

.list-header-fixed {
    position: fixed;
    width: calc(100% - 210px);
    z-index: 2;
}

.list-area {
    border: none;
    padding-inline: 30px;
    // border-top: 1px solid #ececec !important;
}

.filter-area {
    background-color: white;
    min-height: 55px;
    padding-right: 35px !important;
    padding-top: 30px;

    .search-box {
        position: relative;
    }
}

.description-info {
    color: #707070;
    font-size: 12px;
}

.workflow-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}

.workflow-box {
    width: 100%;
    height: 100%;
    padding: 8px 0 10px 0;
    box-sizing: border-box;
    border: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    background: #fff;
    border-radius: 10px;
    position: relative;
    z-index: 1000;
    transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.workflow-box:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.language-dropdown {
    float: left;

    #language-toggle {
        width: 102px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        // background-color: rgba(40, 49, 67, 0.08);
        border-radius: 5px;
        text-decoration: none;
        cursor: pointer;
        padding: 0;

        &:hover {
            background-color: rgba(186, 194, 208, 0.23);
        }

        #lang-image {
            color: rgb(65, 65, 70);
        }

        .name {
            user-select: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgb(65, 65, 70);
        }

        #dropdown-icon {
            color: rgb(65, 65, 70);
        }
    }

    #language-menu {
        margin-top: 3px;
        min-width: 102px;
        inset: unset !important;
        padding: 0;

        .language-item {
            cursor: pointer;
            height: 30px;
            padding: 3px 0 9px 16px;
            user-select: none;
            color: rgb(65, 65, 70);

            &:hover {
                background-color: rgba(186, 194, 208, 0.23);
            }

            &:active {
                background-color: rgba(136, 142, 153, 0.23);
            }

            .dropdown-item {

                &:hover,
                &:active {
                    background-color: unset !important;
                    color: rgb(47, 47, 50);
                }
            }
        }
    }
}

.h-30 {
    height: 30%;
}

.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.position-absolute-aligned {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.multiple-image-container {
    background-color: #f0f0f0;
    border-radius: 3px;
    position: relative;

    .multiple-image-div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }
}

.label-span {
    border-radius: 14px;
    padding-inline: 9px;
    padding-top: 1px;
    padding-bottom: 2px;
    background-color: #EBEFF8;
    color: #314B76;
}

.open-report-button {
    background-color: #EFF1F5;
    color: #314B76;
    border-radius: 10px;
    padding-top: 4px;
    font-size: 14px;
    padding-inline: 35px;
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 120px;
}

.status-dropdown {
    height: 34px;
    padding-left: 12px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    color: #fff !important;
    width: 80%;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px;
    font-size: 15px !important;
    border-right: 1px;

    &.open {
        background-color: #EBEFF8 !important;
        color: #314B76 !important;
        border-color: #EBEFF8 !important;

        &:hover {
            border-right-color: #EBEFF8 !important;
            color: #314B76 !important;
            font-size: 15px !important;
        }

        &:active {
            background-color: #EBEFF8 !important;
            color: #314B76 !important;
            border-color: #EBEFF8 !important;
        }

        &:focus {
            background-color: #EBEFF8 !important;
            color: #314B76 !important;
            border-color: #EBEFF8 !important;
        }
    }

    &.in-progress {
        background-color: #66D8E5 !important;
        border-color: #66D8E5 !important;

        &:hover {
            border-right-color: #66D8E5 !important;
            color: #fff !important;
            font-size: 15px !important;
        }

        &:active {
            background-color: #66D8E5 !important;
            color: #fff !important;
            border-color: #66D8E5 !important;
        }

        &:focus {
            background-color: #66D8E5 !important;
            color: #fff !important;
            border-color: #66D8E5 !important;
        }
    }

    &.on-hold {
        background-color: #707070 !important;
        border-color: #707070 !important;

        &:hover {
            border-right-color: #707070 !important;
            color: #fff !important;
            font-size: 15px !important;
        }

        &:active {
            background-color: #707070 !important;
            color: #fff !important;
            border-color: #707070 !important;
        }

        &:focus {
            background-color: #707070 !important;
            color: #fff !important;
            border-color: #707070 !important;
        }
    }

    &.done {
        background-color: #24A148 !important;
        border-color: #24A148 !important;

        &:hover {
            border-right-color: #24A148 !important;
            color: #fff !important;
            font-size: 15px !important;
        }

        &:active {
            background-color: #24A148 !important;
            color: #fff !important;
            border-color: #24A148 !important;
        }

        &:focus {
            background-color: #24A148 !important;
            color: #fff !important;
            border-color: #24A148 !important;
        }
    }
}

.status-toggle {
    border-color: #eff1f5 !important;
    height: 34px;
    width: 30px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: white !important;
    padding-top: 1px;

    &.open {
        background-color: #EBEFF8 !important;
        border-left-color: #EBEFF8 !important;
        color: #314B76 !important;
        border-color: #EBEFF8 !important;

        &:hover {
            border-left-color: #EBEFF8 !important;
            color: #314B76 !important;
        }

        &:active {
            background-color: #EBEFF8 !important;
            color: #314B76 !important;
            border-left-color: #EBEFF8 !important;
        }

        &:focus {
            background-color: #EBEFF8 !important;
            color: #314B76 !important;
            border-left-color: #EBEFF8 !important;
        }
    }

    &.in-progress {
        background-color: #66D8E5 !important;
        border-left-color: #66D8E5 !important;
        border-color: #66D8E5 !important;

        &:hover {
            border-left-color: #66D8E5 !important;
            color: #fff !important;
        }

        &:active {
            background-color: #66D8E5 !important;
            color: #fff !important;
            border-left-color: #66D8E5 !important;
        }

        &:focus {
            background-color: #66D8E5 !important;
            color: #fff !important;
            border-left-color: #66D8E5 !important;
        }
    }

    &.on-hold {
        background-color: #707070 !important;
        border-left-color: #707070 !important;
        border-color: #707070 !important;

        &:hover {
            border-left-color: #707070 !important;
            color: #fff !important;
        }

        &:active {
            background-color: #707070 !important;
            color: #fff !important;
            border-left-color: #707070 !important;
            border-color: #707070 !important;
        }

        &:focus {
            background-color: #707070 !important;
            color: #fff !important;
            border-left-color: #707070 !important;
            border-color: #707070 !important;
        }
    }

    &.done {
        background-color: #24A148 !important;
        border-left-color: #24A148 !important;
        border-color: #24A148 !important;

        &:hover {
            border-left-color: #24A148 !important;
            color: #fff !important;
        }

        &:active {
            background-color: #24A148 !important;
            color: #fff !important;
            border-left-color: #24A148 !important;
            border-color: #24A148 !important;
        }

        &:focus {
            background-color: #24A148 !important;
            color: #fff !important;
            border-left-color: #24A148 !important;
            border-color: #24A148 !important;
        }
    }
}

.status-wrapper-list {
    min-width: 70px;
    width: auto !important;

    .open {
        background-color: #EBEFF8 !important;
        width: auto !important;
    }

    .in-progress {
        background-color: #66D8E5 !important;
        width: auto !important;
    }

    .on-hold {
        background-color: #707070 !important;
        width: auto !important;
    }

    .done {
        background-color: #24A148 !important;
        width: auto !important;
    }

    .status-dropdown-icon {
        margin-bottom: 3px;
        margin-right: 3px;
    }
}

.priority-dropdown {
    background-color: #EBEFF8 !important;
    height: 34px;
    color: #314B76 !important;
    width: 80%;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px;
    font-weight: 400;
    padding-left: 20px;
    font-size: 15px !important;

    &:hover {
        border-right-color: #EBEFF8 !important;
        color: #314B76 !important;
        font-size: 15px !important;
    }

    &:active {
        background-color: #EBEFF8 !important;
        color: #314B76 !important;
        font-size: 15px !important;
    }

    &:focus {
        background-color: #EBEFF8 !important;
        color: #314B76 !important;
        font-size: 15px !important;
    }
}

.priority-toggle {
    border-color: #eff1f5 !important;
    border-left-color: #EBEFF8 !important;
    height: 34px;
    width: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #EBEFF8 !important;
    color: #314B76 !important;
    padding-top: 5px;

    &:hover {
        border-left-color: #EBEFF8 !important;
    }

    &:active {
        background-color: #EBEFF8 !important;
    }

    &:focus {
        background-color: #EBEFF8 !important;
    }
}

.priority-wrapper-list {
    min-width: 70px;
    width: auto !important;

    .high {
        color: #DA1E28 !important;
    }

    .medium {
        color: #FF7F00 !important;
    }

    .low {
        color: #F1C21B !important;
    }
}

.tab-area {
    min-height: 50px;
    border: none;
    background-color: #F6F6F6;
    padding-left: 20px;

    &.first-area {
        padding-top: 95px;
    }
}

.tab-item {
    cursor: pointer;
    min-height: 50px;
    padding-top: 15px;
    color: #707070;


    &.right {
        min-width: auto;
    }

    &.left {
        min-width: auto;
    }

    &.selected-tab {
        color: #003DA6;
        border-bottom: solid 2px #003DA6;
        pointer-events: none;
    }

    &:hover {
        background-color: #e8e8e8;
        color: #2059BF;
        border-bottom: solid 2px #2059BF;
    }

    .tab-title {
        padding-inline: 5px;
    }
}

.change-layout-link {
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #2059BF !important;
    }
}

.empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 130px;
}